.contact {
  background-color: white !important;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 5vh;
}

.contact Input {
  color: #ff591c;
}
#contact {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  margin: 0 5rem;
}
.contactHr {
  width: 50px;
  border-top: 1px solid white !important;
}

.lead {
  color: #a73537;
  text-align: center;
  font-size: 25px !important;
  font-weight: bolder !important;
  margin-top: 25px !important;
  margin-bottom: 35px !important;
}
.form-group {
  max-width: 80%;
  padding: 0 0 0 20%;
  margin-bottom: 0 !important;
}

#exampleName,
#exampleEmail,
#exampleNumber,
#date,
#creneau {
  background: #c2a25f !important;
  border: 2px solid #a73537 !important;
  color: #ff591c;
  font-weight: bold;
  border-radius: 10px;
}
#exampleName,
#exampleEmail,
#exampleNumber,
#date,
#creneau,
#exampleText:focus {
  outline: none;
}
#RGPD {
  background: #c2a25f !important;
  color: #ff591c !important;
}
.MuiCheckbox-root{
  color: #ff591c !important;
}
.RGPDGroup {
  align-self: baseline;
  position: relative;
  left: 2vw;
  top: 3vh;
  margin-bottom: 5vh !important;
}
#exampleText {
  height: 300px;
  background: #c2a25f !important;
  border: 2px solid #a73537 !important;
  color: #ff591c;
  font-weight: bold;
  border-radius: 10px;
}
.buttonGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.MuiMenuItem-root:hover{
  background-color: rgba(255, 89, 28, 0.04) !important;
}
.MuiButtonBase-root{
color: #ff591c !important;
}

/* .contactButton {
  color: #ff591c !important;
  font-weight: bold;  
  transition: 0.3s ease;
  background-color: #a73537 !important;
  box-shadow: 2px 2px 5px 0.5px rgba(54, 54, 54, 0.4) !important;
  z-index: 3000;
} */


.calendar-container {
  display: flex !important;
  justify-content: center !important;
}
@media (max-width: 1025px) {
  .container {
    width: fit-content;
  }
  #contact .buttonGroup {
    margin-bottom: 70px !important;
  }
}

