/**{border:1px solid black;}*/
p {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.5em;
}
.pContact p{
  text-align: left;
}
.infos {
  text-align: right;
  color: #e45828;
}
nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  background-color: #ffd372;
  z-index: 10;
  padding:20px;
}
#navBarG{
  background-color: rgba(255,211,114,0.7);
}
#porfolio{
  margin-top: 20px;
}
#logo-creyoweb-nav {
  width: 70px;
  height: auto;
}
.pContact {
  text-align: left;
  margin: 5vh auto;
  max-width: 400px;
  padding: 30px;
}
#idLexique{
  margin-top: 30px;
 padding: 20px;
}
#idLexique > ul, #idLexique > ul ul{
  padding-inline-start: 20px;
}
#idLexique > ul,#idLexique > ul > ul > li,#idLexique > ul > ul > ul > li,#idRenseigne > ul > li{
  list-style: none;
}
.divXP {
  text-align: center;
}
.divXP > h2,
.divXP > h1 {
  text-align: center;
}
.container {
  margin: 20px;
}
h1 {
  text-align: center;
  margin: 20px;
}
h2 {
  text-align: center;
}
h3 {
  text-align: center;
  font-size: 1.2rem;
  /* color: #ffd372; */
}
h4 {
  text-align: center;
  font-size: 1.2rem;
}
.projectLinks {
  padding: 15px;
  text-decoration: none;
  background-color: #fff6ce;
  border-radius: 50px;
  border: 5px solid #ff7800;
  box-sizing: inherit;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.dossiers {
  text-decoration: none;
  padding: 2px;
  border-radius: 5px;
}

.divlink {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: space-between;
  margin: 15px;
  text-align: center;
  margin-top: 30px;
}
.divimg {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  height: 10%;
}
.divimg img {
  width: auto;
  height: 20em;
  border-radius: 15px;
  border: 2px solid black;
}

.contimg {
  margin: auto;
}

.divLinkItem:hover .description {
  display: block;
  position: absolute;
  right: 0px;
}
.description {
  display: none;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #fff6ce;
  font-weight: bolder;
  top: 45px;
}

.spanValidation{
  text-align: right;
  position: relative;

}
.divLinkItem {
  margin-bottom: 460px;
  position: relative;
}
.projectLinks {
  margin: 15px;
}
strong {
  font-size: 20px;
  font-weight: bolder;
   color: #ff7800; 
}
.icon {
  border: 3px solid #ff7800;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.divIcon {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.divIcon a:hover {
  background-color: inherit;
  box-shadow: inherit;
}
.divimg h2 {
  text-align: center !important;
}
.UMLtitle {
  text-align: center !important;
}
#btnCv {
  width: 100%;
}

#actuel {
  background-color: #fff6ce;
}
#inachevé {
  background-color: #ff7800;
  box-shadow: -2px 2px 23px #ff7800, 2px -2px 23px #ff7800;
}
#opquast,
#bge,
#simplon,
#inachevé,
#freelance,
#actuel {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  margin-right: 20px;
  border-style: inherit;
}
#opquast,
#bge,
#freelance,
#simplon {
  background-color: green;
  box-shadow: -2px 2px 23px green, 2px -2px 23px green;
}
.formations {
  margin: 20px;
  position: relative;
}
/* .formations strong{
	 color: #fff6ce; 
} */
.formations h2,
.formations h3,
.formations strong,
.formations span {
  position: relative;
}
/* #imgOpquast {
  position: absolute;
  left: 550px;
  bottom: 0;
  border: 3px solid #ff7800;
} */
.divOpquast {
  position: relative;
}

