.devis {
  margin-top: 50px;
  display: flex;
  flex-flow: column nowrap;
}

.aide
  > .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.aide > .css-10hburv-MuiTypography-root {
  cursor: help !important;

}
