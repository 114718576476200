/* *{
    border: 1px solid white;
} */
#idRenseigne:hover #idNavPortfolio {
  display: block;
}
body {
  height: 100% !important;
  width: 100% !important;
  margin: 0px;
  padding: 0px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  position: relative;
  cursor: default;
}
.liPortfolio{
  cursor: pointer;
  padding: 10px;
  background-color: #e0bc6f;
  list-style: none;
  border-radius: 50px;
  text-align: center;
  margin: 5px;
  font-weight: bold;
}
#listPortfolio{
  display: flex;
  flex-flow: row wrap;
}
.liPortfolio:hover{
  color: #ff591c;
}
.progresser{

     border-radius:50px;
      border:5px solid #ff591c;
}
.spanValidation {
  color: #ff591c;
}

.aide {
  cursor: help !important;
  color: #ff591c;
  font-weight: bold;
}

::selection {
  background-color: #ff591c;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ff591c;
  margin-block-start: 0;
}
.h3List {
  color: #af5121;
}
#canvas {
  position: relative;
  z-index: 0;
  top: 0px;
}
a {
  text-decoration: none;
}
.square {
  border: 5px solid #a73537;
  padding: 20px;
}

.btnList {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  margin: 0 auto;
  padding: 10px;
}

.divSpansBtn {
  position: relative;
  box-sizing: content-box;
  margin-top: 10px;
}
#devisBtn,
#send {
  background-color: #af5121;
  color: #ffd372;
}
#send {
  cursor: url("../media/icons/pinceau2.png") 10 -60, auto;
}
#devisBtn a {
  color: #ffd372;
}
#devisBtn:hover a {
  color: #ffd372;
}
#devisBtn:hover,
#send:hover {
  background-color: #ff591c;
}
.divSpansBtn > button,
a {
  font-weight: 600;
}
button {
  font-size: medium;
  margin: 5px;
}

.divSpansBtn > button {
  position: relative;
  display: block;
  border-radius: 50px;
  padding: 15px;
  margin: 0 auto;
  border-style: inherit;
  box-shadow: 2px 2px 5px 0.5px rgba(54, 54, 54, 0.4) !important;

  /* appearance: inherit; */
}
.env {
  text-align: left !important;
}
.div-logo {
  text-align: center;
  position: relative;
  margin-top: 60px;
}
.div-logo h3 {
  text-align: center;
}
#logo-creyoweb {
  width: 40%;
  height: auto;
  z-index: 10;
  /* height: auto; */
}
#affiche-creyoweb{
  width: 100%;
  height: auto;
}
footer {
  padding: 30px;
}
footer > a {
  position: relative;
  height: auto;
}
#copyright {
  display: inline-block;
}
#métier {
  text-align: center;
  color: #ff591c;
  margin-left: 15px;
}
canvas {
  /* border: 1px solid white; */ /*creer un scroll lateral*/
  width: 100%;
}
.oList > li > ol > li:hover,
.oList > li > ul > li:hover {
  color: #ff591c;
}
.labelCompetence {
  display: block;
  padding: 10px;
}
.divoList > li::marker {
  font-weight: bolder;
  color: #a73537;
  font-size: 1.5rem;
}
.divoList {
  padding: 20px;
}
.divoList > li {
  text-align: center;
}
.oList {
  padding-inline-start: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.divoList > ol > li::marker {
  font-size: 1.3rem;
}
progress[value] {
  -webkit-appearance: none;
  appearance: none;
}
progress[value]::-webkit-progress-bar {
  background-color: #af5121;
}
::-webkit-progress-value {
  background-color: #ff591c;
}
progress {
  width: 50vw;
  height: 20px;
}
.divCompetence {
  text-align: center;
}
#complink:hover {
  color: #af5121;
}
#budgetGlobal {
  background-color: #af5121 !important;
}
.images {
  background-color: #e0bc6f;
  padding-top: 20px;
}
.images h3 {
  color: #ff591c;
}
