a:hover,
button:hover {
  cursor: url("../media/icons/pinceau.png") 15 -100, auto;
}

a {
  color: #ff591c;
}

a:hover {
  color: #ffd372;
}

body {
  background-color: #ffd372;
}

footer {
  background-color: #a73537;
}

/* button:hover {
  background-color: #e45828;
  color: #ffd372;
} */
.MuiFormLabel-root {
  color: #a73537 !important;
}
.Mui-required {
  color: #ff591c !important;
} /*# sourceMappingURL=theme.css.map */
li {
  color: #a73537;
}
.MuiListItemText-root {
  color: #ff591c !important;
}
.MuiTypography-root
  .MuiTypography-body1
  .MuiListItemText-primary
  .css-10hburv-MuiTypography-root {
  width: fit-content !important;
}

.MuiLinearProgress-determinate {
  width: 50%;
}
.Mui-focused {
  color: #ff591c !important;
}
.MuiButtonBase-root-MuiRadio-root {
  color: #ff591c !important;
}
.Mui-checked {
  color: #ff591c !important;
}
.MuiInputBase-input .MuiOutlinedInput-input {
  color: #ff591c !important;
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}
.MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-aszu04-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-28fuu2-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-1hl1iu7-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.css-e363es-MuiFormControl-root {
  margin: 20px !important;
  margin-top: 20px;
  align-self: center;
}
.css-1cmpscz-MuiButtonBase-root-MuiCheckbox-root {
  margin: 0 auto !important;
}
#rgpdText {
  text-align: center !important;
}
