@media screen and (max-width: 1430px) {
  .smallBlueBtnList > div,
  .smallOrangeBtnList > div {
    position: relative;
  }
  .paraB,
  .paraO {
    margin-right: 42px;
    right: 0;
  }
}

@media screen and (min-width: 720px) {
  #navBarG {
    position: fixed;
    margin: 0;
    width: 100%;
  }
  #bodySite {
    padding-top: 100px;
  }
  #listPortfolio {
    flex-flow: column wrap;
  }
}

@media screen and (max-width: 720px) {
  #logo-creyoweb {
    width: 100%;
    height: 100%;
  }
  nav {
    flex-direction: column;
    align-items: center;
  }
  .board {
    width: 100%;
  }
  .divSpansBtn {
    text-align: center;
  }
  #imgOpquast {
    left: 300px;
  }
}
@media screen and (max-width: 600px) {
  .board,
  .btnList {
    flex-flow: row wrap;
  }
  .smallBtnList {
    position: relative;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: inherit;
  }
  .smallBlueBtnList,
  .smallOrangeBtnList {
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .smallBlueBtn,
  .smallOrangeBtn {
    width: 0.8rem;
    height: 0.8rem;
    margin: 5px;
  }
  .paraO {
    margin-left: inherit;
    top: 25px;
  }
  .btnList {
    justify-content: space-between;
    margin: inherit;
    width: 100%;
  }
  #on-off {
    margin: 0;
  }
  .board {
    height: inherit;
  }
  .paraB,
  .paraO {
    margin-right: inherit;
    right: inherit;
  }
  .projectLinks {
    font-size: 0.8rem;
    display: block;
  }
  .divLinkItem {
    margin-bottom: 10px;
  }
  .divLinkItem:hover .description {
    position: inherit;
    top: 0;
  }
  .chart-container {
    display: block;
  }
  .chart-container {
    width: 250px;
    height: 250px;
  }
}
@media screen and (max-width: 550px) {
  .formations h2,
  .formations h3,
  .formations strong,
  .formations span {
    left: 20px;
  }
  #imgOpquast {
    position: static;
    display: block;
    left: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 300px) {
  .paraB,
  .paraO {
    margin-left: 20px !important;
  }
  h1 {
    font-size: 0.8rem;
  }
  .divoList {
    width: 250px;
    padding: 10px;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 100px;
    -webkit-appearance: none;
    border-radius: 30px;
    background-color: #e28915af;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    margin-top: -1px;
    color: #0d9213;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: pointer;
    border-radius: 100%;
    background: #a14e00;
    box-shadow: -80px 0 0 80px #ff7800;
  }
}
