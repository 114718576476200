$dark-brown: #a73537;
$low-brown: #af5121;
$hight-orange: #ff591c;
$low-orange: #e45828;
$yellow: #ffd372;
a:hover,
button:hover,
#send:hover {
  cursor: url("../media/icons/pinceau.png") 10 -60, auto;
  // cursor: url('../media/icons/goutte.png') 5 5, auto;
}
a:hover {
  color: $yellow;
}
body {
  background-color: $yellow;
}
footer {
  background-color: $dark-brown;
}
button {
  background-color: $dark-brown;
  color: $hight-orange;
}
button:hover {
  background-color: $low-orange;
  color: $yellow;
}

:export {
  darkBrown: #a73537;
  lowBrown: #af5121;
  hightOrange: #ff591c;
  lowOrange: #e45828;
  yellow: #ffd372;
}
